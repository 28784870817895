import {
  TokenBNBLogo,
  TokenBTCLogo,
  TokenCFXLogo,
  TokenDAILogo,
  TokenDefaultLogo,
  TokenETHLogo,
  TokenEmptyLogo,
  TokenUSDCLogo,
  TokenUSDTLogo,
} from '../assets/svg'

export const TokenConfig = {
}

export function TokenIconSrc(token) {
  let icon
  switch (token) {
    case 'BNB':
      icon = TokenBNBLogo
      break
    case 'BTC':
      icon = TokenBTCLogo
      break
    case 'CFX':
      icon = TokenCFXLogo
      break
    case 'DAI':
      icon = TokenDAILogo
      break
    case 'ETH':
      icon = TokenETHLogo
      break
    case 'USDC':
      icon = TokenUSDCLogo
      break
    case 'USDT':
      icon = TokenUSDTLogo
      break
    default:
      if (token?.length) icon = TokenDefaultLogo
      else icon = TokenEmptyLogo
      break
  }
  return icon
}

export const AddressOne = '0x0000000000000000000000000000000000000001';

export const tokenContractsInfo = {
  // eth mainnet
  '1+0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48': { // USDC
    symbol: 'USDC',
    name: 'USDC',
    pegged: '0xad03020ac793f956a4fcf652a7e7624a30dcfc62',
    priceName: 'USDCUSDT',
  },
  '1+0xdac17f958d2ee523a2206206994597c13d831ec7': { // USDT
    symbol: 'USDT',
    name: 'USDT',
    pegged: '0x0ffaa757feff390d1718743fbdb8447bfd695696',
    priceName: 'USDCUSDT',
  },

  '11155111+0x46e988dc4c0a149f83d09281d7f31fe9e65d9156': { // eth sepolia USDT
    symbol: 'USDT',
    name: 'USDT',
    pegged: '0xa5000d7bd6afcb205206efd06602c9d36681656a',
    priceName: 'USDCUSDT',
  },
  '11155111+0x5a8e3980d4e9052b116bbd5278d8483167060f2f': { // eth sepolia USDC
    symbol: 'USDC',
    name: 'USDC',
    pegged: '0x40cfe7905e375f2d1158e9a9d297e4e51f74a619',
    priceName: 'USDCUSDT',
  },

  '1030+0x6963EfED0aB40F6C3d7BdA44A05dcf1437C44372': { // USDC eSpace
    symbol: 'USDC',
    name: 'USDC',
    pegged: '0xad03020ac793f956a4fcf652a7e7624a30dcfc62',
    priceName: 'USDCUSDT',
  },
  '1030+0xfe97E85d13ABD9c1c33384E796F10B73905637cE': { // USDT eSpace
    symbol: 'USDT',
    name: 'USDT',
    pegged: '0xcbd2a243c0f2c6f0dd9fee178bc8cb724c8e57e9',
    priceName: 'USDCUSDT',
  },

  // cfx eSpace testnet
  '71+0x0000000000000000000000000000000000000001': { // CFX
    symbol: 'CFX',
    name: 'CFX',
    pegged: '0x797e0038eee764aaa897efd09f2a28bccfbf1a3c',
    priceName: 'CFXUSDT',
  },
  '71+0x7c8Db2BdB4A0EE722D8D4B70D2a02c15DDBaF517': { // ETH
    symbol: 'zgETH',
    name: 'zgETH',
    pegged: '0xc7f56d86a7c33834a5a9f38562979efd9ef7e8a3',
    priceName: 'ETHUSDT',
  },
  '71+0xa84D84BDBB4Abce2d1aef6bE85323953F877E01D': { // USDC
    symbol: 'zgUSDC',
    name: 'zgUSDC',
    pegged: '0x207581a4d99d450aa85e8f90a6a58d700df3c508',
    priceName: 'USDCUSDT',
  },

  // bsc testnet
  '97+0x1cb9ee88a3c37a564364be9f4450c9d62a2c505d': { // CFX
    symbol: 'bCfx',
    name: 'bCfx',
    pegged: '0x45510d80073543a1c3a140728135a58e22a4d2c8',
    priceName: 'CFXUSDT',
  },
  '97+0x839f685FF05f3CcC6b8030c2BE23f09A39ed170D': { // ETH
    symbol: 'zgETH',
    name: 'zgETH',
    pegged: '0xc90fbb6794ec15bb0b129c43a24420922b78c5db',
    priceName: 'ETHUSDT',
  },
  '97+0x64a23998EbCEb3138F1C7C0E7A6C029C0166B294': { // USDC
    symbol: 'zgUSDC',
    name: 'zgUSDC',
    pegged: '0x34490ebee40a26e025b93911ebfb541dad2220ab',
    priceName: 'USDCUSDT',
  },

  // Optimism Goerli
  '420+0x51ec0cfcb174ae37712d9041f6d29269c0d23cfd': { // CFX
    symbol: 'bCfx',
    name: 'bCfx',
  },
  '420+0x1397F53e72AF8776Cc7dBAa468fb191b3d2C2531': { // ETH
    symbol: 'zgETH',
    name: 'zgETH',
  },
  '420+0x2757dC0AF733B79314bc5dBFA91b2922c18ECA88': { // USDC
    symbol: 'zgUSDC',
    name: 'zgUSDC',
  },

  // Arbitrum Goerli
  '421613+0x4c764b397de55948e0ba66b644b1f1f37fa4dd62': { // CFX
    symbol: 'bCfx',
    name: 'bCfx',
  },
  '421613+0x781F8b25a1Bb374A7c8fcff551f493397377106E': { // ETH
    symbol: 'zgETH',
    name: 'zgETH',
  },
  '421613+0x6a88cC44C07654bC0ba5fA45450956a1a9868658': { // USDC
    symbol: 'zgUSDC',
    name: 'zgUSDC',
  },

  // Avalanche
  '43113+0x7BDDD49F9E3c924f347e47c7B5BA4a55393A610B': { // CFX
    symbol: 'bCfx',
    name: 'bCfx',
  },
  '421613+0x51Ec0Cfcb174AE37712D9041F6d29269c0d23CfD': { // ETH
    symbol: 'zgETH',
    name: 'zgETH',
  },
  '421613+0x8C0b5f22C24c2BCDd38D21a3AfcB0D2e7F67D397': { // USDC
    symbol: 'zgUSDC',
    name: 'zgUSDC',
  },
};

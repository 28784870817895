const { useState, useEffect } = require("react");
// import { provider as fluentProvider } from '@cfxjs/use-wallet'
import { format } from 'js-conflux-sdk';
import { useAccount, Unit } from '@cfxjs/use-wallet';

export const useTrackCfxErc20Balance = (tokenAddress) => {
  const account = useAccount();

  const [balance, setBalance] = useState(undefined);

  useEffect(() => {
    if (!account) {
      setBalance(undefined);
      return;
    }
    const timer = setInterval(() => {
      const fluentProvider = window.fluent;
      if (!fluentProvider) return;
      fluentProvider.request({
        method: `cfx_call`,
        params: [
            {
                data: '0x70a08231000000000000000000000000' + format.hexAddress(account).slice(2),
                to: tokenAddress,
            },
            'latest_state',
        ],
      })
      .then((minUnitBalance) => {
        setBalance(Unit.fromMinUnit(minUnitBalance))
      })
      .catch(() => {})
    }, 3000)

    return () => clearInterval(timer);
  }, [account, tokenAddress]);

  return balance;
}

/* eslint-disable react-hooks/exhaustive-deps */
import {useMemo, useState, useCallback, useEffect} from 'react'
import {connect, useStatus, useChainId, useBalance as useFluentBalance } from '@cfxjs/use-wallet'
import {ERC20_ABI} from '../abi'
import {MainnetCfx, TestnetCfx, TypeConnectWallet, KeyOfCfx} from '../constants'
import {getChainIdRight} from '../utils'
import {checkCfxTokenAddress} from '../utils/address'
import { useTrackCfxErc20Balance } from './useTrackCfxErc20Balance'
// eslint-disable-next-line
import { useAccount as useFluentAccount } from '@cfxjs/use-wallet-react/conflux/Fluent';

function getESpaceProvider() {
  return window.conflux;
}

function useChainNetId() {
  const coreChainId = useChainId()
  const [chainId, setChainId] = useState(coreChainId);

  const isCore = coreChainId === MainnetCfx || coreChainId === TestnetCfx
  const provider = getESpaceProvider()

  useEffect(() => {
    if (!coreChainId) return;
    if (isCore) {
      setChainId(coreChainId)
    } else {
      provider.request({
        method: 'eth_chainId'
      }).then(data => {
        setChainId('' + parseInt(data));
      }).catch(err => {
        console.error(err);
      })
    }
  }, [coreChainId, isCore, provider])

  return {
    chainId,
    isCore,
  }
}

// function useFluentAccount() {
//   const coreAccount = useAccount();
//   const [account, setAccount] = useState(coreAccount);

//   const {isCore, chainId} = useChainNetId();
//   const provider = getESpaceProvider()
//   useEffect(() => {
//     if (isCore) {
//       setAccount(coreAccount);
//       return;
//     }
//     if (!provider) return;
//     provider.request({
//       method: 'eth_accounts',
//     }).then(accounts => {
//       setAccount(accounts[0]);
//     });
//   }, [chainId, coreAccount, isCore, provider])

//   return account;
// }

export function useConnect() {
  const status = useStatus()
  const address = useFluentAccount()
  const [error, setError] = useState(null)
  const {chainId, isCore} = useChainNetId()
  const type = useMemo(() => {
    if (status === 'not-active') return TypeConnectWallet.error
    if (status === 'active') return TypeConnectWallet.success
    if (status === 'not-installed') return TypeConnectWallet.uninstalled
    if (status === 'in-activating') return TypeConnectWallet.loading
    return TypeConnectWallet.loading
  }, [status])

  if (window && window.conflux && window.conflux.autoRefreshOnNetworkChange)
    window.conflux.autoRefreshOnNetworkChange = false

  const login = useCallback(() => {
    const p = connect()
    p.catch(err => {
      setError(err)
      if (err.code === 4001) {
        // EIP-1193 userRejectedRequest error
        // If this happens, the user rejected the connection request.
        console.error('Please connect to ConfluxPortal.')
      } else {
        console.error(err)
      }
    })
    return p
  }, [])

  return {
    type,
    activate: login,
    error,
    address,
    chainId,
    isCore,
  }
}

export function useContract(address, ABI) {
  const confluxJS = window?.confluxJS
  const {chainId} = useConnect(KeyOfCfx)
  const isChainIdRight =
    getChainIdRight(KeyOfCfx, chainId, address, 'contract') || !address
  return useMemo(
    () => {
      if (!ABI || !confluxJS || !isChainIdRight) return null
      try {
        return confluxJS.Contract({abi: ABI, address})
      } catch (error) {
        console.log('get cfx contract error: ', error)
        return null
      }
    },
    [address, Boolean(confluxJS)],
    isChainIdRight,
  )
}

export function useTokenContract(tokenAddress) {
  return useContract(tokenAddress || '', ERC20_ABI)
}

/**
 * get CFX balance from Conflux Network
 * @returns balance of account
 */
// eslint-disable-next-line no-unused-vars
export function useNativeTokenBalance(_) {
  const balance = useFluentBalance();
  return balance ? balance.toDecimalMinUnit() : null;
}

export function useTokenBalance(tokenAddress) {
  // eslint-disable-next-line no-unused-vars
  const balance = useTrackCfxErc20Balance(tokenAddress);
  return balance ? balance.toDecimalMinUnit() : null;
}

export function useBalance(address, tokenAddress) {
  const isNativeToken = !checkCfxTokenAddress(tokenAddress, 'contract')
  const tokenBalance = useTokenBalance(tokenAddress)
  const nativeTokenBalance = useNativeTokenBalance(address)
  return isNativeToken ? nativeTokenBalance : tokenBalance
}


/**
 * call some method from contract and get the value
 * @param {*} contract
 * @param {*} method
 * @param {*} params
 * @returns
 */
export function useContractState(tokenAddress, method, params, interval) {
  const contract = useTokenContract(tokenAddress)
  const [data, setData] = useState(null)

  useEffect(() => {
    const getContractData = async params => {
      try {
        const res = await contract?.[method](...params)
        setData(res)
      } catch (error) {
        setData(null)
      }
    }

    if (interval) {
      getContractData(params)
      const timeInterval = setInterval(() => getContractData(params), interval)
      return () => clearInterval(timeInterval)
    } else {
      getContractData(params)
    }
  }, [...params, interval, Boolean(contract)])

  return data
}

import PropTypes from 'prop-types'
import {useMemo, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Input, Modal, Tabs} from '../../../components'
import { TokenIcon } from '../../components';
import SVGZGSearchIcon from '../../../assets/svg/ZGSearchIcon'
import SvgPlus from '../../../assets/svg/color/Plus';
import { useWallet } from '../../../hooks/useZGWallet';
import { SvgClear } from '../../../assets/svg';

const TokenModal = ({open, onClose, setToken, tokenList, wallet, tokenModalTitle}) => {
  const handleChooseFromToken = token => {
    setToken(token)
    onClose()
  }
  const { t } = useTranslation()
  const { connector, chainId } = useWallet(wallet)

  const [searchKey, setSearchKey] = useState('');
  const [searchPart, setSearchPart] = useState('token');

  const handleClickAddToken = (token) => {
    if (token.sfChain === 'cfx') return;
    if (!connector) return;
    const {token_address, symbol, decimals, icon} = token;

    connector.getProvider().then(provider => {
      provider.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: token_address,
            symbol,
            decimals,
            image: icon,
          },
        },
      }).catch(() => {
        console.log('add token failed');
      });
    })
  };

  const showAddToken = (token) => {
    if (token.sfChain === 'cfx') return false;
    if (!connector) return false;
    if (token.token_address === '0x0000000000000000000000000000000000000001') return false;
    const {chain_id} = token;
    return chainId === chain_id;
  };

  const sortedTokenList = useMemo(() => tokenList.sort((a, b) => {
    if (searchPart === 'token') {
      if (a.symbol === b.symbol) return a.chainName.localeCompare(b.chainName);
      return a.symbol.localeCompare(b.symbol);
    }
    if (a.chainName === b.chainName) return a.symbol.localeCompare(b.symbol);
    return (a.chainName || '').localeCompare(b.chainName);
  }), [tokenList, searchPart]);

  return (
    <Modal
      width="w-[736px]"
      open={open}
      onClose={onClose}
      closeIconClassName="w-[32px] h-[32px]"
      content={
        <div className='mt-[60px]'>
          <p className='text-[24px] font-semibold	mb-[40px]'>{tokenModalTitle}</p>
          <Input
            width="w-full"
            customPrefix={<SVGZGSearchIcon />}
            placeholder={t('zgPortal.tokenModal.searchPlaceholder')}
            value={searchKey}
            onChange={e => setSearchKey(e.target.value)}
            suffix={searchKey ? <SvgClear onClick={() => setSearchKey('')} /> : null}
          />
          <div className='w-full flex justify-end'>
            <Tabs
              className='my-4'
              tabs={[
                {label: t('zgPortal.tokenModal.tokenTab'), value: 'token'},
                {label: t('zgPortal.tokenModal.networkTab'), value: 'network'},
              ]}
              onSelect={(item) => {setSearchPart(item.value)}}
            />
          </div>
          <p className='text-[20px] font-medium'>{searchKey ? `${t('zgPortal.tokenModal.resultTip')} ${searchKey}` : t('zgPortal.tokenModal.allTokens')}</p>
          <div className='h-px bg-[#A9ABB2] mt-[12px] mb-[8px]' />
          <div className='overflow-scroll h-[300px]'>
            {sortedTokenList.filter(token => {
              if (searchPart === 'token') {
                return (token.symbol || '').toLowerCase().includes(searchKey.toLowerCase())
              } else {
                return (token.chainName || '').toLowerCase().includes(searchKey.toLowerCase())
              }
            }).map(token => (
              <button
                key={token.id}
                className="block py-4 rounded-[12px] hover:bg-[#EAECEF] w-full pl-[10px]"
                onClick={() => handleChooseFromToken(token)}
              >
                <div className="flex items-center">
                  <TokenIcon token={token} size={46}/>
                  <div className="h-full flex flex-col justify-center items-start ml-3">
                    <div className='flex gap-[4px]'>
                      <p className='text-[20px] color-[#1E1E1E] font-semibold leading-[130%]'>
                        {token.symbol}
                      </p>
                      {showAddToken(token) && <SvgPlus className='w-[16px] h-[16px] mt-[6px]' onClick={() => handleClickAddToken(token)} />}
                    </div>
                    <p className='text-[16px] color-[#1E1E1E]'>{token.chainName}</p>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      }
    />
  )
}

TokenModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setToken: PropTypes.func,
  tokenList: PropTypes.array,
  wallet: PropTypes.string,
  tokenModalTitle: PropTypes.string,
}

export default TokenModal

import Big from 'big.js'
import { IS_DEV } from '../utils'

Big.DP = 40
Big.NE = -40
Big.PE = 1000

export const NetworkContextName = 'NETWORK'

export const TypeConnectWallet = {
  uninstalled: 'uninstalled',
  loading: 'loading',
  error: 'error',
  success: 'success',
}

export const ProxyUrlPrefix = {
  shuttleflow: '/rpctx',
  sponsor: '/rpctoken',
  zg: '/rpcnext',
}

/**
 * interval time config
 */
export const IntervalTime = {
  fetchBalance: 3000,
  fetchTokenList: 6000,
}

export const BigNumZero = new Big(0)

export const ZeroAddrHex = '0x0000000000000000000000000000000000000000'

export const TxReceiptModalType = {
  ongoing: 'ongoing',
  success: 'success',
  error: 'error',
}

export const SendStatus = {
  ongoing: 'waiting',
  success: 'success',
  error: 'failed',
  claim: 'claim',
}

export const ClaimStatus = {
  ongoing: 'ongoing',
  success: 'success',
  error: 'error',
}

export const MobileBreakpoint = 768

export const Decimal18 = '18'

export const TypeAccountStatus = {
  unconnected: 'unconnected',
  success: 'success',
  error: 'error',
}

/**
 * Type of cached tx type
 */
export const TypeTransaction = {
  transaction: 'transaction', //common transaction,for example: send for native token or transfer for erc20 token
  approve: 'approve', //approve for erc20 token
}

/**
 * Status of shuttle transaction, mainly for local environment
 */
export const TransactionStatus = {
  pending: 'pending',
  waiting: 'waiting',
  success: 'success',
  error: 'error',
  skip: 'skip',
}

/**
 * Status of zg deposit
 */
export const DepositStatus = {
  wait_for_confirm: 'WAIT_FOR_CONFIRM',
  wait_for_signature: 'WAIT_FOR_SIGNATURE',
  wait_for_claim: 'WAIT_FOR_CLAIM',
  claiming: 'CLAIMING',
  claimed: 'CLAIMED',
  failed: 'CLAIM_FAILED',
}

export const UserOperationStatus = {
  doing: 'doing',
  finished: 'finished',
}

export const Millisecond = {
  day: 24 * 60 * 60 * 1000,
}

export const ClaimButtonType = {
  twoStep: 'twoStep',
  common: 'common',
}

export const TxStatus = {
  submitted: 'submitted',
  success: 'success',
  error: 'error',
}

export const supportedPools = IS_DEV ? [
  '97+0x1cb9ee88a3c37a564364be9f4450c9d62a2c505d',
  '97+0x839f685FF05f3CcC6b8030c2BE23f09A39ed170D',
  '97+0x64a23998EbCEb3138F1C7C0E7A6C029C0166B294',
  '71+0x0000000000000000000000000000000000000001',
  '71+0x7c8Db2BdB4A0EE722D8D4B70D2a02c15DDBaF517',
  '71+0xa84D84BDBB4Abce2d1aef6bE85323953F877E01D',
] : [
  '1+0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  '1+0xdac17f958d2ee523a2206206994597c13d831ec7',
  '1030+0x6963EfED0aB40F6C3d7BdA44A05dcf1437C44372',
  '1030+0xfe97E85d13ABD9c1c33384E796F10B73905637cE',
]

export * from './chainConfig'
export * from './contractConfig'
export * from './tokenConfig'
export * from './walletConfig'

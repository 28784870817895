import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './i18n'
import App from './pages/App'
import posthog from 'posthog-js'
// eslint-disable-next-line
import { completeDetect as completeDetectConflux } from '@cfxjs/use-wallet-react/conflux/Fluent';

Promise.all([completeDetectConflux()]).then(() => {
  console.log('completeDetectConflux')
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.querySelector('#root'),
  )
})

if (window.location.host === 'portal.zglabs.org') {
  posthog.init('phc_kAbQtRA97KcP7VK19DRgGgBHxnUyObp0mpGssgnipIU', { api_host: 'https://app.posthog.com' })
}

// TO DO: add web vitals when necessary

// import reportWebVitals from './reportWebVitals'
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()

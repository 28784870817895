import {IS_DEV} from '../utils'
import {
  checkHexAddress,
  checkCfxTokenAddress,
  checkBtcAddress,
} from '../utils/address'
import {
  NetworkArbitrumLogo,
  // NetworkAvalancheLogo,
  NetworkBitcoinLogo,
  NetworkBSCLogo,
  NetworkConfluxLogo,
  NetworkEthereumLogo,
  NetworkOECLogo,
  NetworkOptimismLogo,
  NetworkEmptyLogo,
  NetworkAvalancheLogo,
  NetworkDefaultLogo,
} from '../assets/svg'
import {KeyOfMetaMask, KeyOfPortal} from './walletConfig'

/**
 * ethereum config
 */
export const KeyOfEth = 'eth'
export const ScanUrlEth = IS_DEV
  ? 'https://sepolia.etherscan.io/'
  : 'https://etherscan.io'
export const ChainIdEth = IS_DEV ? '11155111' : '1'
export const ChainNameEth = IS_DEV ? 'Ethereum Sepolia' : 'Ethereum'

/**
 * arbitum one config
 */
export const KeyOfArb = 'arbitrum'
export const ScanUrlArb = IS_DEV
  ? 'https://goerli.arbiscan.io/'
  : 'https://arbiscan.io/'
export const ChainIdArb = IS_DEV ? '421613' : '42161'
export const ChainNameArb = IS_DEV ? 'Arbitrum Goerli' : 'Arbitrum One'

/**
 * optimism config
 */
export const KeyOfOp = 'optimism'
export const ScanUrlOp = IS_DEV
  ? 'https://goerli-optimism.etherscan.io/'
  : 'https://optimistic.etherscan.io/'
export const ChainIdOp = IS_DEV ? '420' : '10'
export const ChainNameOp = IS_DEV ? 'Optimism Goerli' : 'Optimism'

/**
 * avalanche config
 */
export const KeyOfAvax = 'avalanche'
export const ScanUrlAvax = IS_DEV
  ? 'https://testnet.avascan.info/'
  : 'https://avascan.info/'
export const ChainIdAvax = IS_DEV ? '43113' : '43114'
export const ChainNameAvax = IS_DEV ? 'Avalanche Fuji Testnet' : 'Avalanche'

/**
 * bsc config
 */
export const KeyOfBsc = 'bsc'
export const ScanUrlBsc = IS_DEV
  ? 'https://testnet.bscscan.com'
  : 'https://bscscan.com'
export const ChainIdBsc = IS_DEV ? '97' : '56'
export const ChainNameBsc = IS_DEV
  ? 'Binance Smart Chain Testnet'
  : 'Binance Smart Chain'

/**
 * oec config
 */
export const KeyOfOec = 'oec'
export const ScanUrlOec = IS_DEV
  ? 'https://www.oklink.com/okexchain-test'
  : 'https://www.oklink.com/okexchain'
export const ChainIdOec = IS_DEV ? '65' : '66'
export const ChainNameOec = IS_DEV ? 'OKExChain Testnet' : 'OKExChain'

/**
 * conflux eSpace config
 */
export const KeyOfCfxESpace = 'cfxESpace'
export const ScanUrlCfxESpace = IS_DEV
  ? 'https://evmtestnet.confluxscan.io/'
  : 'https://evm.confluxscan.io/'
export const ChainIdCfxEspace = IS_DEV ? '71' : '1030'
export const ChainNameCfxEspace = IS_DEV
  ? 'Conflux eSpace Testnet'
  : 'Conflux eSpace'

/**
 * conflux config
 */
export const KeyOfCfx = 'cfx'
export const ScanUrlCfx = IS_DEV
  ? 'https://testnet.confluxscan.io'
  : 'https://confluxscan.io'
export const MainnetCfx = '1029'
export const TestnetCfx = '1'
export const ChainIdCfx = IS_DEV ? TestnetCfx : MainnetCfx
export const ChainNameCfx = IS_DEV ? 'Conflux Testnet' : 'Conflux'

/**
 * bitcoin config
 */
export const KeyOfBtc = 'btc'
export const ScanUrlBtc = IS_DEV
  ? 'https://blockstream.info/testnet'
  : 'https://blockstream.info'

export const ChainIdsBtc = {
  MAINNET: {name: 'Mainnet'},
  TESTNET: {name: 'Testnet'},
}

export const displayFilter = obj => {
  return obj?.supported === 1 && obj?.in_token_list === 1
}

/**
 * chain config
 */
export const ChainConfig = {
  [KeyOfEth]: {
    key: KeyOfEth,
    id: ChainIdEth,
    icon: ChainIconSrc(KeyOfEth),
    fullName: ChainNameEth, //full name of the chain
    shortName: 'Ethereum', // short name of chain, usually used for fetching api
    tokenName: 'ETH', //the name of native token for this chain
    nativeToken: '0x0000000000000000000000000000000000000001',
    provider: null,
    checkAddress: checkHexAddress,
    displayFilter,
    scanUrl: ScanUrlEth,
    scanTxUrl: ScanUrlEth + '/tx/',
    scanTokenUrl: ScanUrlEth + '/token/',
    commonTokens: IS_DEV
      ? [
          'eth',
          '0x08130635368aa28b217a4dfb68e1bf8dc525621c', //AfroX
          '0x27ccd03d1eccb2cbced1efbb18554bbfd526800a', //ecfx
        ]
      : [
          'eth',
          '0xdac17f958d2ee523a2206206994597c13d831ec7', //usdt
          '0xa1f82e14bc09a1b42710df1a8a999b62f294e592', //ecfx
        ],
    wallet: KeyOfMetaMask,
    remainderAmount: 0.15, //when you shuttle in some tokens,for example: ETH-cETH,you must have reminder of this amount to pay fee
    decimals: 18,
    rpcUrl: IS_DEV ? 'https://eth-sepolia.g.alchemy.com/v2/demo' : 'https://eth.blockrazor.xyz',
  },
  [KeyOfArb]: {
    key: KeyOfArb,
    id: ChainIdArb,
    icon: ChainIconSrc(KeyOfArb),
    fullName: ChainNameArb, //full name of the chain
    shortName: 'Arbitrum One', // short name of chain, usually used for fetching api
    tokenName: 'ETH', //the name of native token for this chain
    nativeToken: '0x0000000000000000000000000000000000000001',
    checkAddress: checkHexAddress,
    displayFilter,
    scanUrl: ScanUrlArb,
    scanTxUrl: ScanUrlArb + '/tx/',
    scanTokenUrl: ScanUrlArb + '/token/',
    commonTokens: IS_DEV ? [] : [],
    wallet: KeyOfMetaMask,
    remainderAmount: 0.15, //when you shuttle in some tokens,for example: ETH-cETH,you must have reminder of this amount to pay fee
    decimals: 18,
    rpcUrl: IS_DEV ? 'https://goerli-rollup.arbitrum.io/rpc' : 'https://arb1.arbitrum.io/rpc',
  },
  [KeyOfOp]: {
    key: KeyOfOp,
    id: ChainIdOp,
    icon: ChainIconSrc(KeyOfOp),
    fullName: ChainNameOp, //full name of the chain
    shortName: 'Optimism', // short name of chain, usually used for fetching api
    tokenName: 'ETH', //the name of native token for this chain
    nativeToken: '0x0000000000000000000000000000000000000001',
    checkAddress: checkHexAddress,
    displayFilter,
    scanUrl: ScanUrlOp,
    scanTxUrl: ScanUrlOp + '/tx/',
    scanTokenUrl: ScanUrlOp + '/token/',
    commonTokens: IS_DEV ? [] : [],
    wallet: KeyOfMetaMask,
    remainderAmount: 0.15, //when you shuttle in some tokens,for example: ETH-cETH,you must have reminder of this amount to pay fee
    decimals: 18,
    rpcUrl: IS_DEV ? 'https://goerli.optimism.io' : 'https://mainnet.optimism.io',
  },
  [KeyOfAvax]: {
    key: KeyOfAvax,
    id: ChainIdAvax,
    icon: ChainIconSrc(KeyOfAvax),
    fullName: ChainNameAvax, //full name of the chain
    shortName: 'Avalanche', // short name of chain, usually used for fetching api
    tokenName: 'AVAX', //the name of native token for this chain
    nativeToken: '0x0000000000000000000000000000000000000001',
    checkAddress: checkHexAddress,
    displayFilter,
    scanUrl: ScanUrlAvax,
    scanTxUrl: ScanUrlAvax + '/tx/',
    scanTokenUrl: ScanUrlAvax + '/token/',
    commonTokens: IS_DEV ? [] : [],
    wallet: KeyOfMetaMask,
    remainderAmount: 0.15, //when you shuttle in some tokens,for example: ETH-cETH,you must have reminder of this amount to pay fee
    decimals: 18,
    rpcUrl: IS_DEV ? 'https://api.avax-test.network/ext/bc/C/rpc' : 'https://api.avax.network/ext/bc/C/rpc',
  },
  [KeyOfBsc]: {
    key: KeyOfBsc,
    id: ChainIdBsc,
    icon: ChainIconSrc(KeyOfBsc),
    fullName: ChainNameBsc,
    shortName: 'BSC',
    tokenName: 'BNB',
    nativeToken: '0x0000000000000000000000000000000000000001',
    checkAddress: checkHexAddress,
    displayFilter,
    scanUrl: ScanUrlBsc,
    scanTxUrl: ScanUrlBsc + '/tx/',
    scanTokenUrl: ScanUrlBsc + '/token/',
    commonTokens: IS_DEV
      ? ['bnb', '0xef3f743830078a9cb5ce39c212ec1ca807e45fe1']
      : ['bnb', '0x045c4324039da91c52c55df5d785385aab073dcf'],
    wallet: KeyOfMetaMask,
    remainderAmount: 0.002,
    decimals: 18,
    rpcUrl: IS_DEV ? 'https://bsc-testnet-dataseed.bnbchain.org' : 'https://bsc-dataseed.binance.org',
  },
  [KeyOfOec]: {
    key: KeyOfOec,
    id: ChainIdOec,
    icon: ChainIconSrc(KeyOfOec),
    fullName: ChainNameOec,
    shortName: 'OEC',
    tokenName: 'OKT',
    nativeToken: '0x0000000000000000000000000000000000000001',
    checkAddress: checkHexAddress,
    displayFilter,
    scanUrl: ScanUrlOec,
    scanTxUrl: ScanUrlOec + '/tx/',
    scanTokenUrl: ScanUrlOec + '/tokenAddr/',
    // commonTokens: ['okt', 'cfxk'],
    commonTokens: IS_DEV
      ? ['okt', '0xae6155367003e028b594f1139f2b6edbcb5bb297']
      : ['okt', '0xfcd4d15f09548cd90efcaf0b1d9531bba670b7b1'],
    wallet: KeyOfMetaMask,
    remainderAmount: 0.001,
    decimals: 18,
    rpcUrl: IS_DEV ? 'https://exchaintestrpc.okex.org' : 'https://exchainrpc.okex.org',
  },
  [KeyOfCfxESpace]: {
    key: KeyOfCfxESpace,
    id: ChainIdCfxEspace,
    icon: ChainIconSrc(KeyOfCfxESpace),
    fullName: ChainNameCfxEspace,
    shortName: 'Conflux eSpace',
    tokenName: 'CFX',
    nativeToken: '0x0000000000000000000000000000000000000001',
    checkAddress: checkHexAddress,
    displayFilter,
    scanUrl: ScanUrlCfxESpace,
    scanTxUrl: ScanUrlCfxESpace + 'tx/',
    scanTokenUrl: ScanUrlCfxESpace + '/address/',
    commonTokens: IS_DEV ? ['cfx'] : [],
    wallet: KeyOfPortal,
    remainderAmount: 1,
    supportedToChain: [KeyOfBsc],
    decimals: 18,
    rpcUrl: IS_DEV ? 'https://evmtestnet.confluxrpc.com' : 'https://evm.confluxrpc.com',
  },
  [KeyOfCfx]: {
    key: KeyOfCfx,
    id: ChainIdCfx,
    icon: ChainIconSrc(KeyOfCfx),
    fullName: ChainNameCfx,
    shortName: 'Conflux',
    tokenName: 'CFX',
    checkAddress: checkCfxTokenAddress,
    displayFilter,
    scanUrl: ScanUrlCfx,
    scanTxUrl: ScanUrlCfx + '/transaction/',
    scanTokenUrl: ScanUrlCfx + '/address/',
    commonTokens: IS_DEV
      ? [
          'cfx',
          'cfxtest:acbp2sm9d1ajzthsep0nkmpm0su0n4dzmeexzdcksf',
          'cfxtest:acceftennya582450e1g227dthfvp8zz1p370pvb6r',
        ]
      : [
          'cfx',
          'cfx:acf2rcsh8payyxpg6xj7b0ztswwh81ute60tsw35j7',
          'cfx:acdrf821t59y12b4guyzckyuw2xf1gfpj2ba0x4sj6',
        ],
    wallet: KeyOfPortal,
    remainderAmount: 1,
    decimals: 18,
    nativeToken: '0x0000000000000000000000000000000000000001',
  },
  [KeyOfBtc]: {
    key: KeyOfBtc,
    id: null,
    icon: ChainIconSrc(KeyOfBtc),
    fullName: 'Bitcoin',
    shortName: 'Bitcoin',
    tokenName: 'BTC',
    checkAddress: checkBtcAddress,
    displayFilter() {
      return true
    },
    scanUrl: ScanUrlBtc,
    scanTxUrl: ScanUrlBtc + '/tx/',
    commonTokens: [],
    remainderAmount: 0,
  },
}

export const SupportedChains = Object.keys(ChainConfig)

// set default chain to FromChain and ToChain when shuttle
export const DefaultFromChain = KeyOfEth
export const DefaultToChain = KeyOfCfx
export const SupportedChainIdsWeb3 = [
  ChainConfig[KeyOfEth].id,
  ChainConfig[KeyOfArb].id,
  ChainConfig[KeyOfOp].id,
  ChainConfig[KeyOfAvax].id,
  ChainConfig[KeyOfBsc].id,
  ChainConfig[KeyOfOec].id,
  ChainConfig[KeyOfCfxESpace].id,
]

export const findChainConfigById = inputId => {
  for (let key in ChainConfig) {
    if (ChainConfig[key].id === inputId) {
      return ChainConfig[key]
    }
  }
  return ''
}

export const findChainKeyById = inputId => {
  for (let key in ChainConfig) {
    if (ChainConfig[key].id === inputId) {
      return key
    }
  }
  return ''
}

export function ChainIconSrc(chain) {
  let icon
  switch (chain) {
    case KeyOfEth:
      icon = NetworkEthereumLogo
      break
    case KeyOfArb:
      icon = NetworkArbitrumLogo
      break
    case KeyOfOp:
      icon = NetworkOptimismLogo
      break
    case KeyOfAvax:
      icon = NetworkAvalancheLogo
      break
    case KeyOfBsc:
      icon = NetworkBSCLogo
      break
    case KeyOfCfxESpace:
    case KeyOfCfx:
      icon = NetworkConfluxLogo
      break
    case KeyOfBtc:
      icon = NetworkBitcoinLogo
      break
    case KeyOfOec:
      icon = NetworkOECLogo
      break
    default:
      if (chain?.length) icon = NetworkDefaultLogo
      else icon = NetworkEmptyLogo
      break
  }
  return icon
}
